import { API_USER_V2 } from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class Profile {

    async getProfile() {
        const result = await Fetch.make().get(API_USER_V2, {authHeader: true})
        return result
    }

    async updateProfile(payload) {
        const result = await Fetch.make().put(API_USER_V2, payload, {authHeader: true})
        return result
    }
}

export default new Profile();