import store from "@/state/store";

export default [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: {
            title: "Login",
            layout: "auth",
            permission: "public",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/forgot-password",
        name: "Forgot password",
        component: () => import("../views/account/forgot-password.vue"),
        meta: {
            title: "Forgot Password",
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({ name: "default" });
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/logout",
        name: "logout",
        meta: {
            title: "Logout",
            authRequired: true,
            layout: "auth",
            permission: "public",
            beforeResolve(routeTo, routeFrom, next) {
                localStorage.clear();
                sessionStorage.clear();
                next();
            },
        },
        component: () => import("../views/auth/logout/basic.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        name: "404",
        meta: {
            title: "Error 404",
            authRequired: true,
            layout: "none",
        },
        component: () => import("../views/auth/errors/404-basic.vue"),
    },
    {
        path: "/auth/500",
        name: "500",
        meta: {
            title: "Error 500",
            authRequired: true,
        },
        component: () => import("../views/auth/errors/500"),
    },
    {
        path: "/auth/logout-basic",
        name: "logout-basic",
        meta: {
            title: "Logout",
            authRequired: true,
        },
        component: () => import("../views/auth/logout/basic"),
    },
    {
        path: "/",
        name: "loading",
        meta: {
            title: "Loading",
            authRequired: true,
            layout: "none",
            permission: "public",
        },
        component: () => import("../views/loading/loading.vue"),
    },
    // {
    //     path: "/home",
    //     name: "home",
    //     meta: {
    //         title: "Trang chủ",
    //         authRequired: true,
    //         layout: "main",
    //         permission: "public",
    //     },
    //     component: () => import("../views/app/home/home.vue"),
    // },
    {
        path: "/dashboard",
        name: "dashboard",
        meta: {
            title: "Trang Công Việc",
            authRequired: true,
            layout: "main",
            permission: "public",
        },
        component: () => import("../views/app/dashboard/dashboard.vue"),
    },
    {
        path: "/profile",
        name: "profile",
        meta: {
            title: "Trang Cá Nhân",
            authRequired: true,
            layout: "main",
            permission: "public",
        },
        component: () => import("../views/app/profile/profile.vue"),
    },
    {
        path: "/prompt",
        name: "prompt",
        meta: {
            title: "Trang Biểu Mãu",
            authRequired: true,
            layout: "main",
            permission: "public",
        },
        component: () => import("../views/app/prompt/prompt.vue"),
    },
    {
        path: "/prompt/create-prompt",
        name: "create-prompt",
        meta: {
            title: "Trang Tạo Biểu Mãu",
            authRequired: true,
            layout: "main",
            permission: "public",
        },
        component: () => import("../views/app/prompt/create-prompt.vue"),
    },
    {
        path: "/prompt/:id",
        name: "detail-prompt",
        meta: {
            title: "Trang Chi Tiết Biểu Mãu",
            authRequired: true,
            layout: "main",
            permission: "public",
        },
        component: () => import("../views/app/prompt/detail-update-prompt.vue"),
    },
    {
        path: "/posts",
        name: "post",
        meta: {
            title: "Trang Bài Viết",
            authRequired: true,
            layout: "main",
            permission: "public",
        },
        component: () => import("../views/app/post/post.vue"),
    },
    {
        path: "/posts/:id",
        name: "create-post",
        meta: {
            title: "Trang Tạo Bài Viết",
            authRequired: true,
            layout: "main",
            permission: "post.general",
        },
        component: () => import("../views/app/post/create-post.vue"),
    },
    {
        path: "/plannings",
        name: "planning",
        meta: {
            title: "Trang Lên Kế Hoạch",
            authRequired: true,
            layout: "main",
            permission: "plan.general",
        },
        component: () => import("../views/app/planning/planning.vue"),
    },
    {
        path: "/plannings/create",
        name: "planning-create",
        meta: {
            title: "Trang Cấu Hình Kế Hoạch",
            authRequired: true,
            layout: "main",
            permission: "plan.general",
        },
        component: () => import("../views/app/planning/planning-create.vue"),
    },
    {
        path: "/plannings/update/:id",
        name: "planning-update",
        meta: {
            title: "Trang Cập Nhật Kế Hoạch",
            authRequired: true,
            layout: "main",
            permission: "plan.general",
        },
        component: () => import("../views/app/planning/planning-update.vue"),
    },
    {
        path: "/plannings/:id",
        name: "planning-detail",
        meta: {
            title: "Trang Chi Tiết Kế Hoạch",
            authRequired: true,
            layout: "main",
            permission: "task.general",
        },
        component: () => import("../views/app/planning/planning-detail.vue"),
    },
    {
        path: "/assistants",
        name: "assistant",
        meta: {
            title: "Trang Trợ Lý AI",
            authRequired: true,
            layout: "main",
            permission: "plan.general",
        },
        component: () => import("../views/app/assistant/assistant.vue"),
    },
    {
        path: "/post-accounts",
        name: "post-account",
        meta: {
            title: "Trang Tài Khoản Đăng Bài",
            authRequired: true,
            layout: "main",
            permission: "posting_account.general",
        },
        component: () => import("../views/app/post-account/post-account.vue"),
    },
    {
        path: "/roles",
        name: "roles",
        meta: {
            title: "Trang Quản Lý Vai Trò",
            authRequired: true,
            layout: "main",
            permission: "role.general",
        },
        component: () => import("../views/app/role/role.vue"),
    },
    {
        path: "/roles/:id",
        name: "role-detail",
        meta: {
            title: "Trang Chi Vai Trò",
            authRequired: true,
            layout: "main",
            permission: "role.general",
        },
        component: () => import("../views/app/permission/permission.vue"),
    },
    {
        path: "/roles/create-role",
        name: "create-role",
        meta: {
            title: "Trang Thêm Vai Trò",
            authRequired: true,
            layout: "main",
            permission: "role.general",
        },
        component: () => import("../views/app/role/create-role.vue"),
    },
    {
        path: "/members",
        name: "members",
        meta: {
            title: "Trang Danh Sách Nhân Viên",
            authRequired: true,
            layout: "main",
            permission: "public",
        },
        component: () => import("../views/app/member/member.vue"),
    },
    {
        path: "/admin-review/:id",
        name: "admin-review",
        meta: {
            title: "Admin Review Bài Viết",
            authRequired: true,
            layout: "none",
            permission: "public",
        },
        component: () => import("../views/app/admin-review/admin-review.vue"),
    },
    {
        path: "/customer-review/:id",
        name: "customer-review",
        meta: {
            title: "Khách Hàng Review Bài Viết",
            authRequired: true,
            layout: "none",
            permission: "public",
        },
        component: () =>
            import("../views/app/customer-review/customer-review.vue"),
    },
    {
        path: "/customers",
        name: "customers",
        meta: {
            title: "Danh Sách Khách Hàng",
            authRequired: true,
            layout: "main",
            permission: "public",
        },
        component: () =>
            import("../views/app/zalo-account/zalo-account.vue"),
    },
    {
        path: "/maintenance",
        name: "maintenance",
        meta: {
            title: "Bảo trì",
            authRequired: true,
            layout: "none",
            permission: "public",
        },
        component: () =>
            import("../views/app/maintenance/maintenance.vue"),
    },
    {
        path: "/test",
        name: "test",
        meta: {
            title: "Testing",
            authRequired: true,
            layout: "none",
            permission: "public",
        },
        component: () =>
            import("../views/app/maintenance/maintenance.vue"),
    },
];
