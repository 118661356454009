import {
    API_LOGIN_FIREBASE,
    API_USER_V2,
    API_LOGOUT,
    API_VERIFY_REVIEW
} from "@/apis/api-endpoint";
import { auth } from "@/firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import Fetch from "@/helpers/fetch";
class Auth {
    async loginGoogle() {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const feedback = await Fetch.make().post(API_LOGIN_FIREBASE, {
                idToken: credential.idToken,
            });
            return feedback;
        } catch (error) {
            return {
                status: "error",
                data: {},
                message: JSON.stringify(error),
            };
        }
    }

    async getPermissions() {
        const result = await Fetch.make().get(API_USER_V2 + '/permissions', {
            authHeader: true,
        });
        return result;
    }

    async logout() {
        const result = await Fetch.make().post(API_LOGOUT,{}, {
            authHeader: true,
        });
        return result;
    }

    async verifyReview(payload) {
        const result = await Fetch.make().post(API_VERIFY_REVIEW, payload, {
            authHeader: true,
        });
        return result;
    }
}

export default new Auth();
