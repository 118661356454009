export const sidebar_routes = [
    // {
    //     link: "/home",
    //     icon: "ri-home-line",
    //     label: "Trang chủ",
    //     permission: "public",
    //     name: 'home',
    // },
    {
        link: "/dashboard",
        icon: "ri-dashboard-2-line",
        label: "Công việc",
        permission: "public",
        name: "dashboard",
    },
    {
        link: "/plannings",
        icon: "ri-calendar-line",
        label: "Kế hoạch",
        permission: "public",
        id: "planning",
        name: "planning",
        // childrens: [
        //     {
        //         link: "/plannings",
        //         icon: "ri-list-settings-line",
        //         label: "Quản lý kế hoạch",
        //         permission: "public",
        //     },
        // ],
    },
    {
        link: "/assistants",
        icon: "ri-robot-line",
        label: "Trợ lý AI",
        permission: "public",
        name: "assistants",
    },
    {
        link: "/post-accounts",
        icon: "ri-account-pin-circle-line",
        label: "Tài khoản đăng bài",
        permission: "posting_account.general",
        name: "post-accounts",
    },
    {
        link: "/customers",
        icon: "ri-customer-service-2-line",
        label: "Khách hàng",
        permission: "public",
        name: "customers",
    },
    {
        link: "/prompt",
        icon: "ri-booklet-line",
        label: "Prompt",
        permission: "public",
        name: "prompt",
    },
    {
        link: "/roles",
        icon: "ri-user-settings-line",
        label: "Vai trò",
        permission: "role.general",
        name: "roles",
    },
    {
        link: "/members",
        icon: "ri-group-2-line",
        label: "Nhân viên",
        permission: "public",
        name: "members",
    },
];
