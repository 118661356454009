import { API_MEMBER_V2 } from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class Member {

    async getMembers(query) {
        const result = await Fetch.make().get(API_MEMBER_V2, {authHeader: true, query})
        return result
    }

    async updateMember(data) {
        const url = `${API_MEMBER_V2}/:id/roles`.replace(":id", data.uuid)
        const result = await Fetch.make().put(url, data, {authHeader: true})
        return result
    }

    async lock (uuid) {
        const url = `${API_MEMBER_V2}/:id/lock`.replace(":id", uuid)
        const result = await Fetch.make().post(url, {}, {authHeader: true})
        return result
    }

    async active (uuid) {
        const url = `${API_MEMBER_V2}/:id/activate`.replace(":id", uuid)
        const result = await Fetch.make().post(url, {}, {authHeader: true})
        return result
    }

    async unlock (uuid) {
        const url = `${API_MEMBER_V2}/:id/unlock`.replace(":id", uuid)
        const result = await Fetch.make().post(url,{}, {authHeader: true})
        return result
    }
}

export default new Member();