
import { API_USER_V2 } from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class Permission {
    async getPermissions() {
        const result = await Fetch.make().get(API_USER_V2 + '/permissions', {authHeader: true})
        return result
    }
}

export default new Permission();